import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import Reportshero from "../components/hero/Reports";
import Layout from "../components/Layout";
import CTA1 from "../components/CTA1";

const Reports = () => {
  return (
    <Layout>
      <Reportshero />
      <Container>
        <Row>
          <Col>
            <h3>Inspection Reports</h3>
            <p>
              Our report is unique from others in the home inspection arena. If
              you were to contemplate the purchase of a used car, you might take
              it to your auto mechanic and ask that he “put it through a 65
              point check” – assessing the basics of the car. Our home
              inspection service takes a similar approach. Having been in the
              home inspection business since 1989, we know the problems that
              homes can develop – we specialize in identifying them. As such, we
              put homes through a “320 point check”. This forces us as
              inspectors to evaluate each and every system and component
              available in a home within the scope of our inspection. As such,
              our report is basically a punch list designed for existing homes.
              Our reports are always:
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <ul>
              <li>TRANSPARENT</li>
              <li>CONSISTENT</li>
              <li>CONCISE</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              We have custom designed a template for our inspections that is
              both very comprehensive and very easy to read for our clients.
              With separate sections covering all components of the home, our
              reports will give you a clear picture of the condition of the home
              you are buying. All reports include digital photos and videos to
              back up and show you exactly what we are taking about and where
              you can find it.
            </p>
            <p>
              You will also have a “Summary Page” summing up the most important
              deficiencies that we find wrong and that need immediate attention.
              And we have included a “Systems Nearing End of Life Page” that
              will forewarn you about impending upgrades you will need to make
              to the major components of the home to include the Roof, Plumbing,
              HVAC, Electric & Water Heater, in the near future!
            </p>
            <p>
              We think you will love our reports and have no trouble at all
              navigating them so that you have a full understanding of the home
              you are intending to purchase and enable yourself to make an
              informed decision on if this is the home to invest in or not? Is
              this house going to become your HOME! After all it is probably the
              single most expensive decision you will make in a lifetime! You
              want to make sure to do your due diligence in making that very
              important decision for yourself and your family.
            </p>
            <p>
              Please call or email the office for a Sample Report! We will send
              one designed for the type of home you are buying be it Single
              Family, Villa, Townhome, Condominium, or Manufactured!
            </p>

            <p>
              Phone:{" "}
              <a href="tel:+17277335186" className="">
                727-733-5186
              </a>
            </p>
            <p>
              <a href="mailto:service@younginspections.com">
                service@younginspections.com
              </a>
            </p>
            <p>Or simply use the Request an Inspection form on this website!</p>
          </Col>
        </Row>
      </Container>
      <CTA1 />
    </Layout>
  );
};

export default Reports;
