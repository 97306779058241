import React from "react";
import { Container } from "react-bootstrap";

const Reportshero = () => {
  return (
    <div className="overlay-hero banner banner-page banner-reports mb-30">
      <Container>
        <div className="overlay-herotxt">
          <h2 className="banner-head">Our Inspection Reports</h2>
        </div>
        <div className="overlay-herosub">
          <span>Over 30 Years of Outstanding Reports</span>
        </div>
      </Container>
    </div>
  );
};

export default Reportshero;
